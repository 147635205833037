<!--生产打料图片列表-->
<template>
    <div class="punchImageList">
        <div class="image-box">
            <h1>
                <span class="icon"><i class="iconfont el-icon-set-up"></i></span>
                开始打料
            </h1>
            <div class="image-wrapper">
                <el-image
                    v-for="(url,index) in product_files"
                    :key="index"
                    :src="url"
                    :preview-src-list="product_files"
                    fit="cover"
                ></el-image>
                <el-image v-if="product_files.length===0">
                    <div slot="error" class="image-null">
                        暂无图片
                    </div>
                </el-image>
            </div>
            <h1>
                <span class="icon"><i class="iconfont el-icon-star-off"></i></span>
                交付评价
            </h1>
            <h2 class="driver-tag">
                司机交付评价
            </h2>
            <div class="image-wrapper">
                <el-image
                    v-for="(url,index) in driver_files"
                    :key="index"
                    :src="url"
                    :preview-src-list="driver_files"
                    fit="cover"
                ></el-image>
                <el-image v-if="driver_files.length===0">
                    <div slot="error" class="image-null">
                        暂无图片
                    </div>
                </el-image>
            </div>
            <h2 class="client-tag">
                客户交付评价
            </h2>
            <div class="image-wrapper">
                <el-image
                    v-for="(url,index) in client_files"
                    :key="index"
                    :src="url"
                    :preview-src-list="client_files"
                    fit="cover"
                ></el-image>
                <el-image v-if="client_files.length===0">
                    <div slot="error" class="image-null">
                        暂无图片
                    </div>
                </el-image>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'punch-image-list',
    props: {
        extr: {
            type: [Array, Object, String],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            pcb_number: '',
            // 开始生产图片
            product_files: [],
            // 司机交付图片
            driver_files: [],
            // 客户交付图片
            client_files: [],
        };
    },
    created() {
        this.pcb_number = this.extr.pcb_number;
        this.getInfo();
    },
    methods: {
        // 获取详情 编辑时
        getInfo() {
            this.$axios.get('/interfaceApi/production/productpcb/scfhmx_picture?pcb_number=' + this.pcb_number)
                .then(res => {
                    if (res.product_files) {
                        const fileIds = res.product_files.split(',');
                        fileIds.forEach(fileId => {
                            if (fileId) {
                                this.getFileDetailFun(fileId, 'product_files');
                                // this.product_files.push(`${this.FILE.FILE_BASE_URL()}file/${fileId}/0`);
                            }
                        });
                    }
                    if (res.driver_files) {
                        const fileIds = res.driver_files.split(',');
                        fileIds.forEach(fileId => {
                            if (fileId) {
                                this.getFileDetailFun(fileId, 'driver_files');
                                // this.driver_files.push(`${this.FILE.FILE_BASE_URL()}file/${fileId}/0`);
                            }
                        });
                    }
                    if (res.client_files) {
                        const fileIds = res.client_files.split(',');
                        fileIds.forEach(fileId => {
                            if (fileId) {
                                this.getFileDetailFun(fileId, 'client_files');
                                // this.client_files.push(`${this.FILE.FILE_BASE_URL()}file/${fileId}/0`);
                            }
                        });
                    }
                });
        },
        getFileDetailFun(id, key) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + id)
                .then(res => {
                    this[key].push(res.preview_url);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};

</script>

<style lang="stylus" scoped>
.punchImageList
    width 100%;
    height 100%;
    overflow-y auto
    padding 0 0.3rem;
    .image-box
        width 100%;
        border-left 2px solid #5588F1
        padding-left 0.33rem
        padding-top 0.2rem;
        h1
            padding-bottom 0.1rem
            font-size 0.22rem
            color #333333
            position relative
            .icon
                position absolute
                left -0.53rem;
                width: 0.36rem;
                height: 0.36rem;
                padding 0.02rem
                border 0.02rem solid #5588F1;
                border-radius 50%;
                overflow hidden
                background #fff
                .iconfont
                    width 100%;
                    height 100%;
                    background: #5588F1;
                    display flex
                    justify-content center
                    align-items center
                    color #fff;
                    border-radius 50%;
                    font-size 0.16rem
        h2
            display inline-block
            border: 1px solid #B7EB8F;
            padding 0.02rem 0.08rem
            border-width 1px
            border-style solid
            margin 0.1rem 0
            &.driver-tag
                border-color #B7EB8F
                background #F6FFED
                color #39AE00
                font-size 0.14rem
            &.client-tag
                border-color #FFE58F
                background #FFFBE6
                color #FA6400
                font-size 0.14rem
        .image-wrapper
            width 100%;
            .el-image
                margin-right 0.2rem
                margin-bottom 0.2rem
                width 1.48rem;
                height 1.48rem
                >>> .image-null
                    width 100%;
                    height 100%;
                    background #f5f7fa
                    display flex
                    justify-content center
                    align-items center
                    color #c0c4cc;
</style>
